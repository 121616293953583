import { atom } from "jotai"
import { atomFamily, atomWithRefresh, splitAtom, unwrap } from "jotai/utils"
import * as z from "zod"
import type { GenResource } from "../../shared/resource"
import type { Resource } from "../../shared/data/resource"

const nanoId = ()=> Math.random().toString(36).substring(2, 5) + Math.random().toString(36).substring(2, 5)

const defaultResources: GenResource[] = [{
    id: nanoId(),
    state: "draft"
  }]

export const j_localResources = atom(defaultResources) 
export const j_remoteResources = atomWithRefresh(async (get)=> {
  const {resources}: {resources: Resource[]} = await fetch("/api/playgrounds/default/resources").then(x=>x.json())
  return resources.map(x=>({
    id: x.id,
    name: x.friendlyName,
    state: x.status,
    tool: {
      name: x.generator.tool,
      args: x.generator.args,
      outputType: x.generator.outputType
    },
    output: x.output
  }) as GenResource )
})
export const j_baseResources = atom((get)=> {
  const remoteResources = get(unwrap(j_remoteResources, (x)=>x ?? []))
  const local = get(j_localResources)
  return remoteResources.filter(x=> !local.find(y=>y.id === x.id)).concat(local)
})

export const j_externalResourceOutputSelection = atom<string|null>(null)
  
export const j_resourceById = atomFamily((id: string) => {
    return atom<GenResource, [(s:GenResource)=> GenResource], void>((get)=> get(j_baseResources).find(x=>x.id === id) ?? {
      ...defaultResources[0],
      id,
      name: "new",
    }, async (get, set, update: (s:GenResource)=>GenResource )=> {
      const current = get(j_baseResources)
      const updated = current.map(x=> x.id === id ? update(x) : x)
      set(j_localResources, updated)
      set(j_externalResourceOutputSelection, id)
    })
  })