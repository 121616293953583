export const ReadableStream: typeof import("stream/web").ReadableStream =
  // biome-ignore lint/suspicious/noExplicitAny: hack
  globalThis.ReadableStream as any;
export const TransformStream: typeof import("stream/web").TransformStream =
  // biome-ignore lint/suspicious/noExplicitAny: hack
  globalThis.TransformStream as any;
export type ReadableStream<T = unknown> =
  import("stream/web").ReadableStream<T>;
export type TransformStream<
  T = unknown,
  U = unknown,
> = import("stream/web").TransformStream<T, U>;

export interface Pipe {
    <A>(value: A): A;
    <A, B>(value: A, fn1: (input: A) => B): B;
    <A, B, C>(value: A, fn1: (input: A) => B, fn2: (input: B) => C): C;
    <A, B, C, D>(
      value: A,
      fn1: (input: A) => B,
      fn2: (input: B) => C,
      fn3: (input: C) => D
    ): D;
    <A, B, C, D, E>(
      value: A,
      fn1: (input: A) => B,
      fn2: (input: B) => C,
      fn3: (input: C) => D,
      fn4: (input: D) => E
    ): E;
    // ... and so on
  }
  
// biome-ignore lint/suspicious/noExplicitAny: <explanation>
// biome-ignore lint/complexity/noBannedTypes: <explanation>
  export const pipe: Pipe = (value: any, ...fns: Function[]): unknown => {
    return fns.reduce((acc, fn) => fn(acc), value);
  };

export const curry = <R, T1, T2 extends unknown[]>(fn: (t: T1, ...args: T2)=> R) => {
    return (t: T1) => (...args: T2) => fn(t, ...args)
  }
  export const curry2 = <R, T1, T2, T3 extends unknown[]>(fn: (t: T1, t2:T2, ...args: T3)=> R) => {
    return (t: T1, t2:T2) => (...args: T3) => fn(t, t2, ...args)
  }



export const scan= curry2(<T, TAcc>(f: (acc: TAcc, chunk: T) => TAcc, initial: TAcc, stream: ReadableStream<T>): ReadableStream<TAcc> => {
    const reader = stream.getReader();
    let acc = initial;
    return new ReadableStream<TAcc>({
        async start(controller) {
            while (true) {
                const { done, value } = await reader.read();
                if (done) {
                    controller.close();
                    break;
                }
                acc = f(acc, value);
                controller.enqueue(acc);
            }
        },
        cancel() {
            reader.cancel();
        }
    });
})

export const map = curry(<T, U>(fn: (x: T) => U | PromiseLike<U>, stream: ReadableStream<T>)=>{
    return stream.pipeThrough(new TransformStream<T, U>({
      async transform(chunk, controller) {
        controller.enqueue(await fn(chunk))
      }
    }))
  })
  
  export const flatMap = curry(<T, U>(fn: (x: T) =>ReadableStream<U>, stream: ReadableStream<T>) =>{
    return stream.pipeThrough(new TransformStream<T, U>({
      async transform(chunk, controller) {
        for await (const c of fn(chunk)){
          controller.enqueue(c)
        }
      }
    }))
  })
  
  export const fromPromise = <T>(p: Promise<T>) => {
    const reader = new ReadableStream({
      async start(controller) {
        const value = await p;
        controller.enqueue(value);
        controller.close();
      },
    });
    return reader;
  }
  
  export const tap = curry(<T>(fn: (x: T) => void | PromiseLike<void>, stream: ReadableStream<T>)=>{
      return stream.pipeThrough(new TransformStream<T, T>({
        async transform(chunk, controller) {
          await fn(chunk)
          controller.enqueue(chunk)
        }
      }))
  })
  
  export function of<T>(data: T): ReadableStream<T> {
      return new ReadableStream<T>({
          async start(controller) {
              controller.enqueue(data)
              controller.close()
          }
      })
    }
  
  export function from<T>(iterable: Iterable<T>): ReadableStream<T> {
    return new ReadableStream<T>({
      async start(controller) {
        for (const value of iterable) {
          controller.enqueue(value)
        }
        controller.close()
      }
    })
  }
  
  /*
  export function convertNodeTransform<T,U>(s: NodeTransformStream){
      return new TransformStream<T, U>({
        start(controller) {
          s.on("data", (data) => controller.enqueue(data));
        },
        async transform(chunk, controller) {
          s.write(chunk as T);
        },
      });
    }
  */