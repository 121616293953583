import React from "react";
import ReactDOM from "react-dom/client";
import { PlaygroundHome } from "./playground";
import "./globals.css"
import { Provider } from "jotai"
import paintWorklet from "./assets/rough-painter.js?url"

CSS.paintWorklet.addModule(paintWorklet);


ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
      <Provider>
      <React.Suspense fallback={<div>Loading...</div>}>
      <PlaygroundHome />
      </React.Suspense>
      </Provider>
    </React.StrictMode>,
  )