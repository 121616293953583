import type React from "react";
import {useCallback, useEffect, useMemo, useRef, useState, useTransition, type FC} from "react";
import "../globals.css";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from "@/components/ui/resizable";
import { Badge } from "@/components/ui/badge";
import { WireframeEditor } from "@/components/WireframeEditor";
import { atom, useAtom, useAtomValue, useSetAtom } from "jotai";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { j_baseResources, j_externalResourceOutputSelection, j_localResources, j_remoteResources, j_resourceById } from "./state";
import { useForm } from "react-hook-form";
import { Form, FormField, FormItem, FormLabel, FormControl, FormMessage } from "@/components/ui/form";
import { Select, SelectValue, SelectTrigger, SelectContent, SelectItem} from "@/components/ui/select";
import { Card, CardHeader, CardContent, CardTitle, CardFooter } from "@/components/ui/card";
import type { GenResource } from "shared/resource";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { unwrap, useAtomCallback } from "jotai/utils";
import { ChevronsUpDown, PlusIcon, TrashIcon, XCircleIcon } from "lucide-react";
import { flatMap, from, pipe, scan, type ReadableStream } from "../../utils/readable-streams"
import { Collapsible, CollapsibleTrigger, CollapsibleContent } from "@radix-ui/react-collapsible";
import { useClickAway } from "react-use";
import type { ClientToolDef } from "common/ai";
import { match, P } from "ts-pattern";


export const j_playgroundId = atom("default")

function closeOpenElements(input: string) {
    const tokens = [...input.matchAll(
        /(?<popen>\()|(?<pclose>\))|(?<bopen>\{)|(?<bclose>\})|(?<tago>\<[^>/]*?\>)|(?<tagc>\<\/[^>]*?>)/g)].flatMap(o=> Object.entries(o.groups).filter(([k,v])=>v ).map(([k,v])=>`${k}-${v}`))
    
    const openTokensStack = [] as ({
        type: "brackets" | "parents"
    } | { type: "parents"} | {type: "tag", tagName: string})[];

    const extractTagName = (t: string) => t.split("-")[1].split(" ")[0].replace(/[<>\/]/g, "")
    for (const t of tokens){
        const lastToken = openTokensStack[openTokensStack.length - 1]
        if (t.startsWith("tago")){
            openTokensStack.push({type: "tag", tagName: extractTagName(t) })
        } else if (t.startsWith("tagc")){
            const tagName = extractTagName(t)
            if (lastToken.type === "tag" && lastToken.tagName === tagName){
                openTokensStack.pop()
            }
        } else if (t.startsWith("bopen")){
            openTokensStack.push({type: "brackets"})
        } else if (t.startsWith("bclose")){
            if (lastToken.type === "brackets"){
                openTokensStack.pop()
            }
        } else if (t.startsWith("popen")){
            openTokensStack.push({type: "parents"})
        } else if (t.startsWith("pclose")){
            if (lastToken.type === "parents"){
                openTokensStack.pop()
            }
        }
    }
    let result = input;
    // Close all remaining elements
    while (true) {
        const token = openTokensStack.pop();
        if (!token){
            break;
        }
        if (token.type === "tag"){
            result += `</${token.tagName}>`;
        } else if (token.type === "brackets"){
            result += "}"
        } else if (token.type === "parents"){
            result += ")"
        }
    }
    return result;
}

const ResourceFromTool = ( {tool, onSubmit }: {tool: ClientToolDef, onSubmit:(input: z.TypeOf<T> )=> void })=> {
   const schema = z.object(Object.fromEntries(Object.entries(tool.args).map(([key, value])=> ([key, new (z[value._def.typeName])(value._def) ]))))
   
   const form = useForm({
    resolver: zodResolver(schema)
   })

    return <Form {...form} >
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8" >
      {Object.entries(schema.shape).map(([key, value])=> {
        return <FormField
        key={key}
        control={form.control}
        name={key}
        render={({ field }) => (
          <FormItem>
            <FormLabel>{value.description || key}</FormLabel>
            <FormControl>
            <Input {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      })}
      <Button type="submit">Generate</Button>
      </form>
      </Form>
}

const extractMessages = (data: ReadableStream<Uint8Array>)=> {
  return pipe(data, scan((acc, chunk)=> {
    const td = new TextDecoder();
    const contents = td.decode(chunk, {stream: true})
    const messages =[]
    let messageContent = acc.message ?? ""
    let openBrackets = acc.openBrackets;
    let inString = false;
    for (let i=0; i<contents.length; i++){
      if (contents[i] === '"' && contents[i-1] !== "\\"){
        inString = !inString;
      }
      if (inString){
        messageContent += contents[i]
        continue;
      }
      if (contents[i] === "{"){
        openBrackets += 1
      }
      
      if (openBrackets > 0){
        messageContent += contents[i]
      }
      if (contents[i] === "}"){
        openBrackets -= 1
      }
      
      if (openBrackets === 0){
        try {
          messages.push(JSON.parse(messageContent))
        } catch (error) {
          console.log("Error parsing message", messageContent)
        }
        
        messageContent = ""
      }
    }
    return {
      openBrackets,
      message: messageContent,
      messages: messages
    }
  }, {
    openBrackets: 0,
    message: "",
    messages: [] as any[]
  }), flatMap(x=> from(x.messages)))
}

const j_tools = atom(async (get)=> await fetch("/api/tools").then(x=> x.json()).then(x=> x.tools as ClientToolDef[]))

const ResourcePanel: React.FC<{resourceId: string}> = ({resourceId})=> {
  const [resource, setResource] = useAtom(j_resourceById(resourceId))
  const availableTools = useAtomValue(j_tools) 
  const [toolName, setToolName ] = useState<string>(resource.tool?.name ?? availableTools[0]!.name)
  const tool = availableTools.find(x=> x.name === toolName)
  if (!tool){
    throw new Error("Tool not found");
    
  }
  const [manualEdit, setManualEdit] = useState(false);
  const isEditing = resource.state === "draft" || manualEdit;
  const [,start] = useTransition()
  const onDelete = useAtomCallback(useCallback(async (get, set, resourceId:string)=> {
    const res = await fetch(`/api/playgrounds/${playgroundId}/resources/${resourceId}`, {
      method: "DELETE"
    });
    set(j_localResources, (g)=> g.filter(x=> x.id !== resourceId))
    await set(j_remoteResources)
    
  }, []))

  const createSubResource = useAtomCallback(useCallback((get, set, data: GenResource)=> {
    set(j_localResources, (g)=> [...g, data])
  }, []))


  const updateResource = useAtomCallback(useCallback((get, set, id: string, data: (g:GenResource)=> GenResource)=> {
    set(j_localResources, (g)=> g.map(x=> x.id === id ? data(x) : x))
  }, []))

  const selectOutputTab = useAtomCallback(useCallback((get, set, resourceId: string)=> {
    set(j_hiddenOutputs, g=> g.filter(x=> x !== resourceId))
    set(j_externalResourceOutputSelection, resourceId)
  }, []))

  const playgroundId = useAtomValue(j_playgroundId)


  return <Card>
    <CardHeader className="relative">
      <CardTitle className="text-lg">{resource.name ?? "New"}</CardTitle>
      <Badge className="absolute right-4 top-4">{resource.state}</Badge>
    </CardHeader>
    <CardContent>
    {isEditing ? 
    <>
    <Select value={toolName} onValueChange={setToolName}>
    <SelectTrigger>
      <SelectValue>{toolName}</SelectValue>
    </SelectTrigger>
    <SelectContent>
      {availableTools.map(x=> <SelectItem key={x.name} value={x.name}>{x.name}</SelectItem>)}
    </SelectContent>
  </Select>
    <ResourceFromTool tool={tool} onSubmit={async (s)=> {
      const toolConfig = {
        name: tool.name,
        args: s,
        outputType: tool.outputType
      }
    setResource((g)=>({
      ...g,
      name: `${toolConfig.outputType}_${g.id}`,
      tool: toolConfig,
      state: "init",
    }));
    const res = await fetch(`/api/playgrounds/${playgroundId}/resources`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        id: resource.id,
        name: resource.name,
        tool: toolConfig
      })
    });
    const data = res.body!;
    for await (const message of extractMessages(data)) {
        if (message.type === "log"){
          console.log(message.data)
        }
        if (message.type === "create-subresource"){
          createSubResource(message.data)
        }
        if (message.type === "data"){
          updateResource(message.resource, s=>({
            ...s,
            state: "generating",
            output: (s.output ?? "") + message.data,
          }))
        }
        if (message.type === "done")
          updateResource(message.resource, s=>({
            ...s,
            state: "done",
            output: s.output
          }))
        }
      }
  }  />
  </> : <>
  <Collapsible>
  <CollapsibleTrigger>
  <Button variant="ghost" size="sm" className="flex flex-row gap-2">
          Args
            <ChevronsUpDown className="h-4 w-4" />
          </Button>
  </CollapsibleTrigger>
  <CollapsibleContent>
  {Object.entries(resource.tool?.args ?? {}).map(([name, value], i)=> 
  (<div className="text-xs p-4" key={name} >
    <div className="font-black">{name}</div>
    <div>{match(value).with(
        { $$resourceId: P.string }, ({$$resourceId})=> <span className="text-yellow-600 font-black">#{$$resourceId}</span>)
        .otherwise(()=> value)
      }</div>
    </div>
  ))}
  </CollapsibleContent>
</Collapsible>
  </>}
  </CardContent>
  <CardFooter className="relative">
   {!isEditing ? <Button variant="outline" size="sm" onClick={x=>selectOutputTab(resourceId)}>Output</Button> : null }
    <div className="absolute right-4 bottom-2 flex flex-row items-center text-xs">
    {resource.id}
    <Button size="icon" variant="ghost" onClick={()=> {
      start(()=>{
        onDelete(resourceId)
      })
    }}><TrashIcon /></Button>
    </div>
  
  </CardFooter>
  </Card> 

}

const ResourcesPanel = ()=> {
  const resources = useAtomValue(useMemo(()=>atom((get)=> get(j_baseResources).map(x=>x.id)),[]))
  console.log(resources)
  const addResource = useAtomCallback(useCallback((get, set)=> {
    const id = Math.random().toString(36).substring(2, 5) + Math.random().toString(36).substring(2, 5)
    set(j_localResources, (g)=> [...g, {
      id,
      state: "draft"
    }])
  },[]))

  return <>
    <div className="flex flex-col gap-4 overflow-y-auto">
    {resources.map(x=> 
      <ResourcePanel key={x} resourceId={x} />
    )}
    <Button variant="ghost" className="self-center justify-self-center content-center justify-center" onClick={addResource}><PlusIcon></PlusIcon> </Button>
    </div>
  </>
}

export const PlaygroundHome: FC = ()=> {
  return <ResizablePanelGroup direction="horizontal" className="fixed top-0 left-0 right-0 bottom-0">
    <ResizablePanel className="rounded-2xl p-2 m-2 border-accent border-2 flex flex-col" defaultSize={30}>
      <Badge className="self-start">Resources</Badge>
    <ResourcesPanel/>
    </ResizablePanel>
    <ResizableHandle withHandle />
    <ResizablePanel className="rounded-2xl p-2 m-2 border-accent border-2 overflow-y-auto flex flex-col">
    <Badge className="self-start">Outputs</Badge>
    <OutputsPanel/>
    </ResizablePanel>
  </ResizablePanelGroup>
}

const j_hiddenOutputs = atom<string[]>([])

const OutputsPanel = ()=> {
  const setHiddenOutputs = useSetAtom(j_hiddenOutputs)
  const availableResources =  useAtomValue(useMemo(()=>atom((get)=> {
    const all = get(j_baseResources)
    const hidden = get(j_hiddenOutputs)
    console.log("hidden", hidden)
    console.log("all", all)
    return all.filter( x => x.state !== "draft").filter(x=> !hidden.includes(x.id))
  }
), []))
  const lastUpdatedResource = useAtomValue(j_externalResourceOutputSelection)
  const [tab, setTab] = useState(lastUpdatedResource)
  const [tabMode, setTabMode] = useState<"latest"|"manual">("latest")
  const tabToUse = tabMode === "latest" ? lastUpdatedResource : tab
  const ref = useRef(null);
  useClickAway(ref, () => {
    setTabMode("latest")
  });
  if (availableResources.length === 0){
    return null
  }
  return <Tabs ref={ref} className="flex flex-col overflow-hidden flex-1" value={tabToUse ?? ""} onValueChange={(x)=> {
    setTabMode("manual")
    setTab(x)
  }} >
    <TabsList className="border-b-2 border-b-accent w-full bg-transparent justify-start overflow-x-auto min-h-9 gap-4 py-0 p-0">
      {availableResources.map((x)=> 
      <div className="flex flex-row items-center border-t-2 border-l-2 border-r-2 px-1 border-accent "><TabsTrigger key={x.id} value={x.id}>
        { x.name ?? x.id }
        
        </TabsTrigger>
        <Button size="icon" variant="ghost" className="h-4 w-4" onClick={(e)=> {
            setHiddenOutputs((g)=> [...g, x.id])
            e.stopPropagation();
        }}><XCircleIcon /></Button>
        </div>)}
    </TabsList>
    <div className="p-2 overflow-y-auto flex flex-col flex-1">
    {availableResources.map( x => <TabsContent asChild key={x.id} value={x.id}>
      <>
      {x.tool?.outputType === "wireframe" && <WireframePanel key={x.id} resourceRef={x.id}/>}
      {x.tool?.outputType === "document" && <pre key={x.id} className="[text-wrap:balance]">{(x.state !== "draft" && x.state !== "init") ? x.output : "" }</pre> }
      {(x.tool?.outputType === "website" && x.state !== "draft" && x.state !== "init") ? <WebsitePanel  key={x.id} resourceRef={x.id} /> : null}
      </>
    </TabsContent>)}
    </div>
  </Tabs>
}

const WebsitePanel: React.FC<{resourceRef: string}> = ({resourceRef})=> {
  const source = useAtomValue(j_resourceById(resourceRef))
  const generatedCode = useMemo(()=> source.state !== "draft" && source.state !== "init" ? (source.output ?? "").replace(/^\`\`\`html(.*)\`\`\`$/s, "$1" ?? "" ) : null, [source])
  const iframeRef = useRef<HTMLIFrameElement>(null)
  const docIsOpen = useRef(false)
  const currentDocPosition = useRef(0)
  const syncContent = useCallback(()=> {
    if (!iframeRef.current || !generatedCode){
      return;
    }
    if (currentDocPosition.current < generatedCode.length){
      const doc = iframeRef.current.contentDocument;
      if (!docIsOpen.current){
        console.log("open doc")
        doc?.open();
        docIsOpen.current = true;
      }
      console.log("write content")
      doc?.write(generatedCode.slice(currentDocPosition.current))
      currentDocPosition.current = generatedCode.length
    }
    if (source.state === "done" && docIsOpen.current && currentDocPosition.current === generatedCode?.length){
      console.log("closing document")
      docIsOpen.current = false;
      iframeRef.current?.contentDocument?.close();
    }
  }, [iframeRef.current,generatedCode, source.state])
  useEffect(()=> {
    syncContent()
  }, [syncContent])
  

  /*
  useEffect(()=> {
    if (source.state === "done" && docIsOpen.current && currentDocPosition.current === generatedCode?.length){
      console.log("closing document")
      docIsOpen.current = false;
      iframeRef.current?.contentDocument?.close();
    }
  }, [iframeRef.current, source.state, currentDocPosition.current, generatedCode?.length])
  */
  return <Tabs className="flex flex-col h-full overflow-hidden" defaultValue="preview">
    <TabsList className="justify-start">
      <TabsTrigger value="preview">Preview</TabsTrigger>
      <TabsTrigger value="source">Source</TabsTrigger>
    </TabsList>
    <TabsContent asChild  value="preview">
      <iframe title="website" ref={(e)=> {
        if (e){
          iframeRef.current = e;
        }
        if (e && !e["synced"] ){
          currentDocPosition.current = 0;
          e["synced"] = true;
          syncContent()
        }
      }} className="flex flex-1 overflow-auto" />
    </TabsContent>
    <TabsContent value="source" asChild className="flex flex-1 overflow-auto">
      <pre className="text-xs">{generatedCode}</pre>
    </TabsContent>
  </Tabs>

}


const WireframePanel: React.FC<{resourceRef: string}> = ({resourceRef})=> {
  const source = useAtomValue(j_resourceById(resourceRef))
  const generatedCode = useMemo(()=> source.state !== "draft" && source.state !== "init" ? source.output : null, [source])
  const [code, setCode] = useState(generatedCode ?? "")
  const [editMode, setEditMode] = useState(false)
  useEffect(()=> {
    if (source.state === "generating"){
      setEditMode(false)
    }
  }, [source.state])
  const isGenerating = source.state === "generating"
  const codeToUse = editMode ? code : ((isGenerating && generatedCode) ? closeOpenElements(generatedCode) : generatedCode) ?? ""
  
  return <WireframeEditor code={codeToUse} onCodeChange={(data)=> {
    if (source.state !== "generating"){
      setEditMode(true)
      setCode(data)
    }
  }}/>
}