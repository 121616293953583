import { initPromise } from "@/lib/esbuild";
import * as W from "@/wireframe/wireframe";
import React, { type FC, useState, useTransition, useCallback, createElement, useMemo, useEffect, use, useRef } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Textarea } from "./ui/textarea";
import { Tabs, TabsList, TabsTrigger, TabsContent } from "@/components/ui/tabs"
import { transform } from "esbuild-wasm";
import ReactDOMServer from "react-dom/server";
import { withErrorBounderyFallBack } from "@/lib/react-utils";


await initPromise;

const importsMap = {
  React,
  Page: W.Page,
  Screen: W.Screen,
  Block: W.Block,
  Input: W.Input,
  Chip: W.Chip,
  Icon: W.Icon,
  HStack: W.HStack,
  VStack: W.VStack,
  Link: W.Link,
  Wrap: W.Wrap,
  FillSpace: W.FillSpace,
  Button: W.Button,
  Text: W.Text,
  Image: W.Image,
  Header: W.Header,
  Cover: W.Cover,
  FloatingBlock: W.FloatingBlock,
  Section: W.Section,
};

const initialCode = `
<Page>
<Screen device="desktop">
    <VStack>
    <HStack>
        <Image placeholder="Monday.com logo" size="sm" aspectRatio={3 / 1} />
        <Text size="sm">Products</Text>
        <Text size="sm">Teams</Text>
        <Text size="sm">Platform</Text>
        <Text size="sm">Resources</Text>
        <FillSpace />
        <Text size="sm">Pricing</Text>
        <Text size="sm">Begin session</Text>
        <Button size="sm" variant="secondary">Contact us</Button>
        <Button size="sm" variant="primary">To start ➜</Button>
    </HStack>
    <Header size="lg">
        The platform developed for a new way of working
    </Header>
    <Text size="md">What would you like to manage?</Text>
    <HStack>
        <Image placeholder="Work Management icon and description" size="md" aspectRatio={1 / 1} />
        <Image placeholder="CRM icon and description" size="md" aspectRatio={1 / 1} />
        <Image placeholder="Dev icon and description" size="md" aspectRatio={1 / 1} />
    </HStack>
    <HStack numberOfRows="2">
        <Image placeholder="Creative and design icon and description" size="md" aspectRatio={1 / 1} />
        <Image placeholder="Operations icon and description" size="md" aspectRatio={1 / 1} />
        <Image placeholder="Marketing icon and description" size="md" aspectRatio={1 / 1} />
        <Image placeholder="Project management icon and description" size="md" aspectRatio={1 / 1} />
        <Image placeholder="Task management icon and description" size="md" aspectRatio={1 / 1} />
        <Image placeholder="HR icon and description" size="md" aspectRatio={1 / 1} />
        <Image placeholder="YOU icon and description" size="md" aspectRatio={1 / 1} />
        <Image placeholder="More workflows icon and description" size="md" aspectRatio={1 / 1} />
    </HStack>
    <Button size="lg" variant="primary">To start ➜</Button>
    <Text size="sm">No credit card required + Unlimited term on Free plan</Text>
    <HStack rows="fluid">
        <Image placeholder="icarros logo" size="sm" aspectRatio={3 / 1} />
        <Image placeholder="CocaCola logo" size="sm" aspectRatio={3 / 1} />
        <Image placeholder="btgpactual logo" size="sm" aspectRatio={3 / 1} />
        <Image placeholder="PicPay logo" size="sm" aspectRatio={3 / 1} />
        <Image placeholder="Suno logo" size="sm" aspectRatio={3 / 1} />
        <Image placeholder="RD logo" size="sm" aspectRatio={3 / 1} />
        <Image placeholder="petlove logo" size="sm" aspectRatio={3 / 1} />
        <Image placeholder="Instituto Ayrton Senna logo" size="sm" aspectRatio={3 / 1} />
        <Image placeholder="even logo" size="sm" aspectRatio={3 / 1} />
    </HStack>
    <Cover backgroundImagePlaceholder="background image" height="sm">
        <Text size="md">
        Enter a universe that will transform your work experience and take advantage of a
        <Link href="#"><Text size="md">portfolio of products</Text></Link>
        designed to make the way you work more flexible and scalable.
        </Text>
    </Cover>
    </VStack>
</Screen>
</Page>
`;

const Editor: FC<{ code: string; onChange: (code: string) => void }> = ({
    code,
    onChange,
  }) => {
    return (
      <Textarea
        onChange={(x) => onChange(x.target.value)}
        value={code}
        style={{ minWidth: "30vw", minHeight: "80vh" }}
      />
    );
  };
  


  const Preview = ({ code }: { code: string }) => {
    const compiledCodePromise = React.useMemo(async () => 
      await transform(`return ()=>${code}`, {
          loader: "jsx",
          platform: "browser",
        }).then(x=>x.code, ex=> ""),[code])
    const compiledCode = use(compiledCodePromise)
  
    const {Comp, html } = useMemo(() => {
      const c = new Function(...Object.keys(importsMap), compiledCode)(
          ...Object.values(importsMap)
        );
        const str = ReactDOMServer.renderToString(createElement(c));
        const node = document.createElement("div");
        node.innerHTML = str;
        /*
          document.body.appendChild(node)
          const svg = elementToSVG(node, {
              
          })
          document.body.removeChild(node)
          */
        //const svgString = new XMLSerializer().serializeToString(svg)
        return {
          Comp: c,
          html: str,
          //  svg: svgString
        };
  
    }, [compiledCode]);
  
    return (
      <div className="wire-html bg-white text-black">
            <Comp />
          </div>
    );
  };



  const Preview2 = ({ code }: { code: string }) => {
    const [error, setError] = useState<Error | null>(null);
    const Comp = useRef<React.ComponentType | null>(null);
    const [codeInUse,setCodeInUse] = useState(code);

    useEffect(() => {
      (async function(){
        try {
          const compiled = await transform(`return ()=>${code}`, {
            loader: "jsx",
            platform: "browser",
          })
          const c = new Function(...Object.keys(importsMap), compiled.code)(
            ...Object.values(importsMap)
          );
          const str = ReactDOMServer.renderToString(createElement(c));
         Comp.current = c;
         setCodeInUse(code);
        } catch (e) {
          setError(e);
        }
      })()
    }, [code]);
    return (
      <div className="wire-html bg-white text-black">
            {Comp.current ? <Comp.current /> : null }
          </div>
    );
  };
  
  const PreviewWithFallback = withErrorBounderyFallBack(Preview)

export const WireframeEditor: FC<{code:string, onCodeChange: (code:string)=> void}> = ({code, onCodeChange}) => {
    //const [code, setCode] = useState(initialCode);
    const [previewCode, setPreviewCode] = useState(code);
    const [isUpdating, startTransition] = useTransition();
    const resetError = React.useRef(() => {});
    useEffect(()=> {
       resetError.current();
      resetError.current = () => {};
      startTransition(() => setPreviewCode(code));
    }, [code])

    return (
      <div style={{ width: "100vw", display: "flex", }}>
        <Tabs defaultValue="preview">
        <TabsList>
          <TabsTrigger value="code">Code</TabsTrigger>
          <TabsTrigger value="preview">Wireframe</TabsTrigger>
        </TabsList>
        <TabsContent value="code">
          <Editor code={code} onChange={onCodeChange} />
        </TabsContent>
        <TabsContent value="preview">
        <React.Suspense>
            <ErrorBoundary
              onError={(e)=> {}}
              fallbackRender={(e) => {
                console.error(e);
                resetError.current = e.resetErrorBoundary;
                return <div>error {`${e.error}`}</div>;
              }}
            >
              <PreviewWithFallback code={previewCode} />
            </ErrorBoundary>
          </React.Suspense>
        </TabsContent>
        
        </Tabs>
      </div>
    )
}

/*
<div style={{ flexDirection: "column", display: "flex" }}>
          <Editor code={code} onChange={onCodeChange} />
        </div>
        <div id="preview" style={{ flexGrow: 1 }}>
          <React.Suspense>
            <ErrorBoundary
              onError={(e)=> {}}
              fallbackRender={(e) => {
                console.error(e);
                resetError.current = e.resetErrorBoundary;
                return <div>error {`${e.error}`}</div>;
              }}
            >
              <PreviewWithFallback code={previewCode} />
            </ErrorBoundary>
          </React.Suspense>
        </div>
*/